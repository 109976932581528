import React from 'react';
import Lottie from  "lottie-react";
import SpaceBoy from "../LottieFiles/codingboy.json";
import Typed from "./Typed.js";
import Tilt from 'react-parallax-tilt';
import Travel from '../images/travel.png';
import { MdOutlineTravelExplore } from "react-icons/md";

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'M <b>SANJAYA BHATTA</b></h1>
          <Typed/>   
        </div>

        <Lottie 
          className="illustration" 
          animationData={SpaceBoy} 
          loop={true} 
        />
        
      </div>

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Brief <b>introduction</b></h1>
          <p>
            I'm passionate about transforming <b>raw initial concepts </b> into <b>impactful</b> websites and products that make a difference in people's lives. 
            I thrive on challenging development projects that push my skills to new heights and allow me to deliver work I'm proud of.<br /><br />

            I am fluent in <b>Python</b>, <b>Javascript</b>, <b>Java</b> and <b>C/C++</b> and know a little bit of <b>Swift</b>. I am working on a few 
            projects in the <b>MERN</b> stack.<br />

            <br/>

            Also, I love to <b>travel</b> and <b>explore</b>. <MdOutlineTravelExplore style={{ verticalAlign: 'middle', marginLeft: '2px'}} />   
          </p>
        </div>
        <Tilt>
          <img className='Travel' src={Travel} alt="" />
        </Tilt>
      </div>
    </div>
  )
}

export default Home