import React from 'react';
import {FaGithub} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    WritterrrDesc : "Writterrr is a web app that will allow users to posts anything they want. Users can register and login. Once they're logged in, they can create or view their own posts. For every content that they post, they can use edit function easily.",
    WritterrrGithub : "https://github.com/itSanjaya/Writterrr",
    WritterrrWebsite : "http://44.202.59.88:3000/login",

    HangmanDesc : "A game where players guess letters to try and reveal a hidden word using a console-based interface. The goal is to identify the word before the allowed attempts run out and the stick figure is hung.",
    HangmanGithub : "https://github.com/itSanjaya/HangmanGame",
    HangmanWebsite : "",

    TicTacToeDesc:"TicTacToe is a game played by two players, turn by turn, using a console-based interface. The goal is to set three marks in a row that is either horizontal, vertical, or diagonal in order to win.",
    TicTacToeGithub:"https://github.com/itSanjaya/TicTacToe-Game",
    TicTacToeWebsite:"",
    
    FTPDesc:"A File Transfer Protocol (FTP) is a secure and efficient method for transferring files between computers over a network, such as the internet. It provides a structured approach to upload, download, and manage files remotely, ensuring data integrity and security during transmission.",
    FTPGithub:"https://github.com/itSanjaya/File-Transfer-Protocol",
    FTPWebsite:"",

    TCPDesc:"Transmission Control Protocol (TCP) is a communication protocol used in computer networks and the internet. It ensures reliable, ordered, and error-checked delivery of data packets between devices. It divides large files into smaller packets and manages their transmission, confirming their arrival and re-sending any lost or corrupted packets.",
    TCPGithub:"https://github.com/itSanjaya/Transmission-Control-Protocol-TCP",
    TCPWebsite:"",

    VFSDesc:"This project focuses on the development of a virtual filesystem (VFS) implemented using object-oriented programming (OOP) principles and suitable data structures. The VFS allows users to interact with a simulated file system environment through a user-friendly interface, enabling various file and folder management operations.",
    VFSGithub:"https://github.com/itSanjaya/Virtual-File-System",
    VFSWebsite:"",

    MachineLearningProjectsDesc:"This project centers on developing machine learning models entirely from scratch, focusing on creating robust computational frameworks that enable machines to learn and make informed decisions autonomously. This hands-on project aims to deepen our understanding of how machine learning works and how to create effective models for solving various complex problems.",
    MachineLearningProjectsGithub:"https://github.com/itSanjaya/Machine-Learning-Projects",
    MachineLearningProjectsWebsite:"",

  }

  let show ='';
  if(desc[projectName + 'Github']===""){
    show="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <h3>{projectName}</h3>
            <br />
            {desc[projectName + 'Desc']}
            <br />
        </div>
        <div>
            <a style={{display:show}} href={desc[projectName + 'Github']} target='_blank' rel="noreferrer">
              <button className='projectbtn'><FaGithub/> Github</button>
            </a>

            <a href={desc[projectName + 'Website']} target='_blank' rel="noreferrer">
              <button className='projectbtn'><CgFileDocument/> Demo</button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox