import React from 'react';
import Skills from './Skills.js';
import Tilt from 'react-parallax-tilt';
import Lottie from "lottie-react"
import Coder from '../LottieFiles/coder.json';

const About = () => {
  return (
    <>
      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading' >Get to <b>know</b> me!</h1>
          <p>
            Hi, my name is <b>Sanjaya Bhatta</b> and I am a recent graduate of <b>New York University Abu Dhabi</b>, with a major in Computer Science and 
            minors in Economics and Applied Mathematics. I am passionate about <b>full stack development</b> and  <b>machine learning</b>.
            Currently, I am seeking part-time roles, freelance gigs, or collaborative projects.

            <br/><br/>
            
            I am enthusiastic about developing scalable <b>tech solutions</b> for real-world challenges. 
            I enjoy participating in hackathons, project-based programs, and entrepreneurship competitions 
            where I constantly <b>learn</b> new languages and frameworks through side projects. 
            My goal is to use my skills to <b>solve</b> everyday problems in the industry and make a <b>positive impact</b> through innovation.
            <br></br>
            <br></br>
            {/* I love to create innovative projects with stunning designs.  */}
            You can check out some of my work in the <a href='/Project'>Projects</a> section.
            You can view my <a href='/Resume'>Resume</a> and reach out to him via <a href="mailto:sanjaya.bhatta@nyu.edu">Email</a> or 
            <a href='https://www.github.com/itSanjaya'> GitHub</a>. Feel free to get in touch with me, social links are in the footer.<br/>

            {/* Apart from coding I love to do photography, you can check out some of my shots here <a href="https://www.instagram.com/imsanjaya_/?hl=en" target='_blank'>Instagram.</a> */}
          </p>
        </div>

        <div>
          <Tilt>
              <Lottie 
              className="illustration" 
              animationData={Coder} 
              loop={true} 
            />
          </Tilt>
        </div>

      </div>
      
      <h1 className='SkillsHeading'>Professional Skillset</h1>
      <div className='skills'>
        
        <Skills skill='Python' />
        <Skills skill='Javascript' />
        <Skills skill='C++' />
        <Skills skill='Java' />
        <Skills skill='React' />
        <Skills skill='Node' />
        <Skills skill='RStudio' />
        <Skills skill='HTML' />
        <Skills skill='Git' />
        <Skills skill='Github' />
        <Skills skill='Docker'/>
        <Skills skill='Figma' />
        <Skills skill='MongoDb' />
        <Skills skill='Vercel' />
        <Skills skill='Npm' />
        <Skills skill='Bootstrap'/>        
      </div>
    </>
  )
}

export default About