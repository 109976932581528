import React from 'react';
import ProjectBox from './ProjectBox';
import TicTacToeImage from '../images/tictactoe.jpg';
import HangmanImage from '../images/hangman.png';
import WritterImage from '../images/writter.jpg';
import FTPImage from '../images/ftp.jpg';
import TCPImage from '../images/tcp.png';
import VFSImage from '../images/vfs3.jpg';
import MLImage from '../images/ml.jpg';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>Projects</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={TicTacToeImage} projectName="TicTacToe" />
        <ProjectBox projectPhoto={HangmanImage} projectName="Hangman" />
        <ProjectBox projectPhoto={VFSImage} projectName="VFS" />
        <ProjectBox projectPhoto={TCPImage} projectName="TCP" />
        <ProjectBox projectPhoto={FTPImage} projectName="FTP" />
        <ProjectBox projectPhoto={MLImage} projectName="MachineLearningProjects" />
        <ProjectBox projectPhoto={WritterImage} projectName="Writterrr" />
        
      </div>

    </div>
  )
}

export default Projects