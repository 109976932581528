import React from 'react';
import {FaGithub, FaLinkedin, FaFacebook, FaInstagramSquare} from "react-icons/fa";
import {GrMail} from "react-icons/gr";
import { FaSquareXTwitter } from "react-icons/fa6";

// import {SiLeetcode} from "react-icons/si"

const Footer = () => {
  return (
    <footer>
      <h4>Copyright &copy; 2024 Sanjaya. All Rights Reserved. </h4>
      <div className='footerLinks'>
        <a href="https://github.com/itSanjaya" target='_blank' rel="noreferrer"><FaGithub/></a>
        <a href="https://www.linkedin.com/in/sanjaya-bhatta/" target='_blank' rel='noreferrer'><FaLinkedin/></a>
        <a href='mailTo:sanjaya.bhatta@nyu.edu' target='_blank' rel="noreferrer"><GrMail/></a>
        <a href='https://x.com/itsbhatta' target='_blank' rel="noreferrer"><FaSquareXTwitter/></a>
        <a href='https://www.facebook.com/sanjaya.bhatta.142/' target='_blank' rel="noreferrer"><FaFacebook/></a>
        <a href='https://www.instagram.com/imsanjaya_/?hl=en' target='_blank' rel="noreferrer"><FaInstagramSquare/></a>
        {/* <a href='https://leetcode.com/itSanjaya/' target='_blank'><SiLeetcode/></a> */}
      </div>
    </footer>
  )
}

export default Footer